<template>
    <v-layout justify-center>
        <v-responsive max-width="1280px" width="100%">
            <v-row class="mt-8" justify="center" align="center">
                <v-col cols="auto" class="pt-0 pb-0 headline text-start">게시글 목록</v-col>
                <v-spacer></v-spacer>
                <v-col cols="auto" class="pt-0 pb-0">
                    <v-btn class="ma-2" color="secondary" rounded @click="showSearch = !showSearch"><v-icon small class="mr-2">mdi-filter-variant</v-icon>검색필터</v-btn>
                </v-col>
            </v-row>

            <v-expand-transition>
                <v-card v-show="showSearch" class="elevation-1 mx-auto">
                    <v-card-title class="subtitle-2 font-weight-bold">검색필터</v-card-title>
                    <v-card-text>
                        <v-row>
                            <v-col cols="12" sm="6" class="py-0">
                                <v-date-field v-model="filter.createdAt[0]" label="작성일자" persistent-placeholder hide-details></v-date-field>
                            </v-col>
                            <v-col cols="12" sm="6" class="py-0">
                                <v-date-field v-model="filter.createdAt[1]" label="작성일자" persistent-placeholder hide-details></v-date-field>
                            </v-col>
                        </v-row>
                        <v-row class="mt-3">
                            <v-col cols="6" md="3" class="py-0">
                                <v-select v-model="filter.searchKey" :items="searchKeys" label="검색조건" hide-details persistent-placeholder></v-select>
                            </v-col>
                            <v-col cols="6" md="3" class="py-0">
                                <v-text-field v-model="filter.searchValue" label="검색어" persistent-placeholder hide-details :disabled="!filter.searchKey"></v-text-field>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" rounded text @click="(page = 1) && search()"><v-icon small class="mt-1 mr-2">mdi-magnify</v-icon>검색</v-btn>
                    </v-card-actions>
                </v-card>
            </v-expand-transition>

            <v-data-table :items="boards" :headers="boardsHeaders.filter((header) => (header.test ? header.test($route.query.code) : true))" disable-sort disable-pagination hide-default-footer class="elevation-1 mt-4">
                <template #[`item.no`]="{ item }">
                    {{ (page - 1) * limit + (+boards.indexOf(item) + 1) }}
                </template>
                <template #[`item.thumb`]="{ item }">
                    <v-row>
                        <v-img v-if="item.thumb" :src="item.thumb" width="54" height="54" contain></v-img>
                    </v-row>
                </template>
                <template #[`item.meta.href`]="{ value }">
                    <v-btn v-if="!!value" icon text :href="value" target="_blank">
                        <v-icon>mdi-link</v-icon>
                    </v-btn>
                    <template v-else>-</template>
                </template>
                <template #[`item.meta.youtube`]="{ value }">
                    <v-btn icon text :href="value" target="_blank">
                        <v-icon>mdi-link</v-icon>
                    </v-btn>
                </template>
                <template #[`item.createdAt`]="{ item }">
                    {{ item.createdAt.toDateTime() }}
                </template>
                <template #[`item.actions`]="{item}">
                    <v-icon small @click="edit(item)" title="수정">mdi-pencil</v-icon>
                    <v-icon v-if="false" small @click="copy(item)" title="복사" class="ml-2">mdi-content-copy</v-icon>
                    <v-icon small @click="remove(item)" title="삭제" class="ml-2">mdi-delete</v-icon>
                </template>
            </v-data-table>
            <v-pagination v-model="page" :length="pageCount" :total-visible="11" class="mt-4 mb-12" @input="search()"></v-pagination>

            <v-btn bottom color="accent" fab fixed right @click="create()">
                <v-icon>mdi-pencil</v-icon>
            </v-btn>
        </v-responsive>
    </v-layout>
</template>

<script>
import api from "@/api";
import VDateField from "@/components/plugins/vuetify/v-date-field.vue";

const boardsHeaders = [
    { text: "no", align: "center", width: 40, value: "no" },
    { text: "썸네일", align: "center", width: 50, value: "thumb" },
    { text: "제목", align: "center", width: 250, value: "subject" },
    { text: "작성자", align: "center", width: 50, value: "writer.name" },
    { text: "작성일", align: "center", width: 70, value: "createdAt" },
    { text: "Actions", width: 40, align: "center", value: "actions" },
];

export default {
    components: {
        VDateField,
    },
    data() {
        return {
            ///////////////////////////////////////////////
            // 검색필터
            ///////////////////////////////////////////////
            showSearch: true,

            filter: {
                code: this.$route.query.code,
                searchKey: this.$route.query.searchKey,
                searchValue: this.$route.query.searchValue,
                createdAt: [(this.$route.query.createdAt || [])[0], (this.$route.query.createdAt || [])[1]],
            },

            page: +this.$route.query.page || 1,
            pageCount: 0,
            limit: 10,

            boards: [],
            boardsHeaders,

            searchKeys: [
                { text: "전체", value: null },
                // { text: "작성자", value: "writer.name" },
                { text: "제목", value: "subject" },
                { text: "내용", value: "content" },
            ],
            searchKey: null,
            searchValue: null,
        };
    },
    mounted() {
        this.init();
    },
    watch: {
        $route() {
            this.search(false);

            Object.assign(this.filter, this.$route.query);
            this.page = +this.$route.query.page || 1;
        },
    },
    methods: {
        init() {
            this.search(false);
        },
        async search(routable = true) {
            try {
                if (routable) {
                    this.$router.push({
                        query: Object.assign({}, this.filter, { page: this.page }),
                    });
                }

                var { summary, boards } = await api.console.boards.gets({
                    headers: {
                        skip: (this.page - 1) * this.limit,
                        limit: this.limit,
                    },
                    params: this.filter,
                });

                this.pageCount = Math.ceil(summary.totalCount / this.limit);
                this.boards = boards;
            } catch (error) {
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },
        create() {
            this.$router.push(`/console/boards/create?code=${this.$route.query.code}`);
        },
        edit(board) {
            this.$router.push(`/console/boards/${board._id}`);
        },
        async remove(board) {
            try {
                if (confirm("게시글을 삭제하시겠습니까?")) {
                    await api.console.boards.delete(board);
                    await this.search(false);

                    alert("삭제되었습니다");
                }
            } catch (error) {
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },
    },
};
</script>

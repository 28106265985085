<template>
    <component :is="display" />
</template>

<script>
export default {
    computed: {
        scope() {
            return this.$store.state.scope;
        },
        display() {
            // if(["console", "notice", "popup", "introduction", "photo"].includes(this.scope)) return () => import("./admin/AdminGatePage.vue");
            if(this.scope.includes(["console"])) return () => import("./admin/AdminGatePage.vue");
            return null;
        },
    },
};
</script>

<template>
    <v-layout justify-center>
        <v-responsive max-width="1200px" width="100%" height="100%" class="shop-product-view">

            <v-row class="px-1" align="center">
                <v-col cols="auto" class="headline">로그기록</v-col>
                <v-spacer/>
                <v-col cols="auto" class="py-0">
                    <v-btn class="mr-1" color="secondary" rounded @click="showSearch = !showSearch">
                        <v-icon small class="mr-2">mdi-filter-variant</v-icon>검색필터
                    </v-btn>
                </v-col>
            </v-row>
            
            <v-expand-transition>
                <v-card v-show="showSearch" class="elevation-1 mt-3 mx-1">
                    <v-card-title class="subtitle-2 font-weight-bold">검색필터</v-card-title>
                    <v-card-text class="pb-0">
                        <v-row>
                            <v-col cols="12" sm="6" class="py-0">
                                <v-date-field v-model="filter.createdAt[0]" label="작성일자" persistent-placeholder hide-details></v-date-field>
                            </v-col>
                            <v-col cols="12" sm="6" class="py-0">
                                <v-date-field v-model="filter.createdAt[1]" label="작성일자" persistent-placeholder hide-details></v-date-field>
                            </v-col>
                        </v-row>
                        <v-row align="center">
                            <v-col cols="12" sm="4">
                                <v-select v-model="filter.searchKey" :items="searchKeys" label="검색조건" persistent-placeholder hide-details @input="filter.searchValue = null;"></v-select>
                            </v-col>
                            <v-col cols="12" sm="4">
                                <v-text-field v-model="filter.searchValue" label="검색어" persistent-placeholder hide-details :disabled="!filter.searchKey" @keydown.enter="page = 1; search(true);"></v-text-field>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" rounded text @click="page = 1; search(true);">
                            <v-icon small class="mt-1 mr-2">mdi-magnify</v-icon>검색
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-expand-transition>
            
            <v-data-table :items="logs" :headers="logsHeaders" hide-default-footer disable-sort class="mt-3 mx-1 elevation-1">
                <template v-slot:item.createdAt="{item}">
                    {{ $dayjs(item.createdAt).format("YYYY-MM-DD HH:mm:ss") }}
                </template>
                <template v-slot:item.user="{item}">
                    {{ item.user.name }}({{ item.user.username }})
                </template>
            </v-data-table>

            <v-pagination v-model="page" :length="pageCount" total-visible="11" class="mt-4 mb-4" @input="search(true)"/>

        </v-responsive>
    </v-layout>
</template>

<script>
import api from "@/api";
import VDateField from "@/components/plugins/vuetify/v-date-field.vue";

export default {
    components: {
        VDateField
    },
    data(){
        return {
            showSearch: true,

            filter: {
                searchKey: this.$route.query.searchKey || null,
                searchValue: this.$route.query.searchValue || null,
                createdAt: [(this.$route.query.createdAt || [])[0], (this.$route.query.createdAt || [])[1]],
            },

            searchKeys: [
                { text: ":: 전체 ::", value: null },
                // { text: "사용자아이디", value: "username" },
                { text: "이벤트대상", value: "event" },
                { text: "과업", value: "task" },
            ],

            page: +this.$route.query.page || 1,
            pageCount: 0,
            limit: 10,

            logs: [],
            logsHeaders: [
                { text: "이벤트 대상", value: "event", align: "center" },
                { text: "과업", value: "task", align: "center" },
                { text: "사용자", value: "user", align: "center" },
                { text: "ip주소", value: "ip", align: "center", width: 200 },
                { text: "날짜", value: "createdAt", align: "center" },
            ]
        }
    },
    mounted(){
        this.init();
    },
    methods: {
        async init(){
            await this.search();
        },

        async search(routable = false){
            if(routable){
                this.$router.push({
                    query: { ...this.filter, page: this.page }
                });
            }

            let { summary, logs } = await api.console.logs.gets({
                headers: {
                    skip: (this.page - 1) * this.limit,
                    limit: this.limit
                },
                params: this.filter
            });

            this.logs = logs;
            this.pageCount = Math.ceil(summary.totalCount / this.limit);
        },
    }
}
</script>
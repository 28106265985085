<template>
    <v-layout justify-center>
        <v-responsive max-width="1200px" width="100%" height="100%" class="shop-product-view">

        <v-row class="px-1" align="center">
            <v-col cols="auto" class="headline">
                <span v-if="$route.params._admin">관리자 상세</span>
                <span v-else>관리자 생성</span>
            </v-col>
            <v-spacer/>
        </v-row>

        <v-card class="mx-1 mt-3">
            <v-card-title class="subtitle-2">기본정보</v-card-title>
            <v-card-text>
                <v-row>
                    <v-col>
                        <v-text-field v-model="admin.username" label="아이디" persistent-placeholder hide-details></v-text-field>
                    </v-col>
                    <v-col>
                        <v-password-field v-model="admin.password" label="비밀번호" persistent-placeholder hide-details></v-password-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-text-field v-model="admin.name" label="담당자(이름)" persistent-placeholder hide-details></v-text-field>
                    </v-col>
                    <v-col>
                        <v-text-field v-model="admin.phone" label="담당자(연락처)" persistent-placeholder hide-details></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-text-field v-model="admin.email" outlined label="담당자(이메일)" persistent-placeholder hide-details></v-text-field>
                    </v-col>
                    <v-card-actions>
                        <v-btn @click="certify">이메일 인증</v-btn>
                    </v-card-actions>
                </v-row>
                <v-row>
                    <v-col>
                        <v-text-field v-model="otp" outlined label="인증 OTP번호" persistent-placeholder hide-details></v-text-field>
                    </v-col>
                    <v-card-actions>
                        <v-btn @click="recovery">OTP 확인</v-btn>
                    </v-card-actions>
                </v-row>
                <v-row>
                    <v-col> 
                        <v-select multiple v-model="admin.scope" :items="items" item-value="value" label="허용게시판" persistent-placeholder hide-details>
                            <template v-slot:item="{ item }">
                                {{ item.text }}
                            </template>
                        </v-select>
                    </v-col>
                    <v-card-actions v-if="$route.params._admin">
                        <v-btn @click="permission">권한 변경</v-btn>
                    </v-card-actions>
                </v-row>
            </v-card-text>
            <v-card-actions />
        </v-card>
        
        <v-row class="my-6">
            <v-spacer/>
            <v-col cols="auto">
                <v-btn color="primary" @click="save">저장</v-btn>
            </v-col>
            <v-spacer/>
        </v-row>
    </v-responsive>
    </v-layout>
</template>

<script>
import api from "@/api";
import CryptoAES from "@/plugins/crypto-aes";

import VPasswordField from "@/components/plugins/vuetify/v-password-field.vue";

export default {
    components: {
        VPasswordField
    },
    data(){
        return {
            showSearch: true,

            otp: null,

            admin: {
                username: null,
                password: null,
                name: null,
                phone: null,
                email: null,
                scope: ['password']
            },

            items: [
                { text: "공지사항", value: "notice" },
                { text: "언론보도", value: "introduction" },
                { text: "포토갤러리", value: "photo" },
                { text: "팝업", value: "popup" },
            ]
        }
    },
    mounted(){
        this.init();
    },
    methods: {
        async init(){
            if (!this.scope.includes("console")) {
                alert("접근할 수 없는 계정입니다")
                this.$router.go(-1)
                return;
            }
            if(this.$route.params._admin){
                let { user } = await api.console.users.get({ _id: this.$route.params._admin });
                this.admin = {
                    ...this.admin,
                    ...user
                };
            }
        },
        validate(){
            try{
                if(!this.admin.username) throw new Error("아이디를 작성해주세요");
                if(!this.admin.name) throw new Error("성함을 작성해주세요");
                if(!this.admin.phone) throw new Error("휴대폰 번호를 작성해주세요");
                if(!this.admin.email) throw new Error("이메일을 작성해주세요");
                if(!this.otp) throw new Error("메일 인증을 진행해주세요");
                return true;
            }
            catch(err) {
                alert(err.message);
            }
        },
        async certify(){
            this.$route.params._admin
            ? await api.console.admins.certify.post({ type: "email", kind: "change", email: this.admin.email, username: this.admin.username })
            : await api.console.admins.certify.post({ type: "email", kind: "join", email: this.admin.email })
        },
        async recovery(){
            if(this.$route.params._admin){
                try{
                    await api.console.admins.recovery.post({ type: "email", kind: "change", otp: this.otp, username: this.admin.username, email: this.admin.email });
                    alert("인증되었습니다");
                } catch(err) {
                    console.log(err);
                    this.otp = null;
                }
            }
            else {
                try{
                    await api.console.admins.recovery.post({ type: "email", kind: "join", otp: this.otp, email: this.admin.email });
                    alert("인증되었습니다");
                } catch(err) {
                    console.log(err);
                    this.otp = null;
                }
            }
        },
        async save(){
            if(this.validate()){
                this.admin._id 
                ? await api.console.users.put({
                    _id: this.admin._id,
                    ...this.admin,
                    password: this.admin.password ? CryptoAES.encrypt(this.admin.password) : undefined
                })
                : await api.console.users.post({
                    ...this.admin,
                    password: this.admin.password ? CryptoAES.encrypt(this.admin.password) : undefined
                });
    
                alert("저장되었습니다");
                this.$router.push(`/console/admins`);
            }
        },
        async permission(){
            await api.console.users.put({
                _id: this.admin._id,
                code: "permission",
                scope: this.admin.scope
            });

            alert("권한이 변경되었습니다");
        }
    },
    computed: {
        scope() {
            return this.$store.state.scope;
        }
    }
}
</script>
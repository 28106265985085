<template>
    <v-layout justify-center>
        <v-responsive max-width="1200px" width="100%" height="100%" class="shop-product-view">

        <v-row class="px-1" align="center">
            <v-col cols="auto" class="headline">
                <span v-if="$route.params._admin">관리자IP 상세</span>
                <span v-else>관리자IP 생성</span>
            </v-col>
            <v-spacer/>
        </v-row>

        <v-card class="mx-1 mt-3">
            <v-card-title class="subtitle-2">기본정보</v-card-title>
            <v-card-text>
                <v-autocomplete v-model="admin" :items="admins" label="ip를 등록할 관리자를 선택해 주세요.">
                    <template v-slot:item="{item}">
                        {{item.name}}
                    </template>  
                    <template v-slot:selection="{item}">
                        {{item.name}}
                    </template>
                </v-autocomplete>
            </v-card-text>
            <v-card-actions />
        </v-card>

        <v-card v-if="admin?._id" class="mx-1 mt-3">
            <v-card-title class="subtitle-2">IP등록</v-card-title>
            <v-card-text>
                <v-text-field v-model="ip" label="등록할 ip를 작성해 주세요"/>
            </v-card-text>
            <v-card-actions />
        </v-card>
        
        <v-row class="my-6">
            <v-spacer/>
            <v-col cols="auto">
                <v-btn color="primary" @click="save">저장</v-btn>
            </v-col>
            <v-spacer/>
        </v-row>

    </v-responsive>
    </v-layout>
</template>

<script>
import api from "@/api";

export default {
    components: {
    },
    data(){
        return {
            showSearch: true,

            admin: null,
            admins:[],
            ip: null
        }
    },
    mounted(){
        this.init();
    },
    methods: {
        async init(){
            let { users } = await api.console.users.gets();
            this.admins = users;
            if(this.$route.params._ip){
                let { ip } = await api.console.ip.get({ _id: this.$route.params._ip });
                let { admin } = await api.console.admins.get({ _id: ip._user });
                this.ip = ip.ip;
                this.admin = admin;
            }
        },

        async save(){
            this.ip._id 
            ? await api.console.ip.put({
                ip: this.ip
            })
            : await api.console.ip.post({
                ip: this.ip,
                _user: this.admin._id
            });

            alert("저장되었습니다");
            this.$router.push(`/console/adminsIp`);
        }
    },
}
</script>
var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-layout', {
    attrs: {
      "justify-center": ""
    }
  }, [_c('v-responsive', {
    staticClass: "shop-product-view",
    attrs: {
      "max-width": "1200px",
      "width": "100%",
      "height": "100%"
    }
  }, [_c('v-row', {
    staticClass: "px-1",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "headline",
    attrs: {
      "cols": "auto"
    }
  }, [_vm.$route.params._admin ? _c('span', [_vm._v("관리자 상세")]) : _c('span', [_vm._v("관리자 생성")])]), _c('v-spacer')], 1), _c('v-card', {
    staticClass: "mx-1 mt-3"
  }, [_c('v-card-title', {
    staticClass: "subtitle-2"
  }, [_vm._v("기본정보")]), _c('v-card-text', [_c('v-row', [_c('v-col', [_c('v-text-field', {
    attrs: {
      "label": "아이디",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.admin.username,
      callback: function ($$v) {
        _vm.$set(_vm.admin, "username", $$v);
      },
      expression: "admin.username"
    }
  })], 1), _c('v-col', [_c('v-password-field', {
    attrs: {
      "label": "비밀번호",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.admin.password,
      callback: function ($$v) {
        _vm.$set(_vm.admin, "password", $$v);
      },
      expression: "admin.password"
    }
  })], 1)], 1), _c('v-row', [_c('v-col', [_c('v-text-field', {
    attrs: {
      "label": "담당자(이름)",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.admin.name,
      callback: function ($$v) {
        _vm.$set(_vm.admin, "name", $$v);
      },
      expression: "admin.name"
    }
  })], 1), _c('v-col', [_c('v-text-field', {
    attrs: {
      "label": "담당자(연락처)",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.admin.phone,
      callback: function ($$v) {
        _vm.$set(_vm.admin, "phone", $$v);
      },
      expression: "admin.phone"
    }
  })], 1)], 1), _c('v-row', [_c('v-col', [_c('v-text-field', {
    attrs: {
      "outlined": "",
      "label": "담당자(이메일)",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.admin.email,
      callback: function ($$v) {
        _vm.$set(_vm.admin, "email", $$v);
      },
      expression: "admin.email"
    }
  })], 1), _c('v-card-actions', [_c('v-btn', {
    on: {
      "click": _vm.certify
    }
  }, [_vm._v("이메일 인증")])], 1)], 1), _c('v-row', [_c('v-col', [_c('v-text-field', {
    attrs: {
      "outlined": "",
      "label": "인증 OTP번호",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.otp,
      callback: function ($$v) {
        _vm.otp = $$v;
      },
      expression: "otp"
    }
  })], 1), _c('v-card-actions', [_c('v-btn', {
    on: {
      "click": _vm.recovery
    }
  }, [_vm._v("OTP 확인")])], 1)], 1), _c('v-row', [_c('v-col', [_c('v-select', {
    attrs: {
      "multiple": "",
      "items": _vm.items,
      "item-value": "value",
      "label": "허용게시판",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    scopedSlots: _vm._u([{
      key: "item",
      fn: function (_ref) {
        var item = _ref.item;
        return [_vm._v(" " + _vm._s(item.text) + " ")];
      }
    }]),
    model: {
      value: _vm.admin.scope,
      callback: function ($$v) {
        _vm.$set(_vm.admin, "scope", $$v);
      },
      expression: "admin.scope"
    }
  })], 1), _vm.$route.params._admin ? _c('v-card-actions', [_c('v-btn', {
    on: {
      "click": _vm.permission
    }
  }, [_vm._v("권한 변경")])], 1) : _vm._e()], 1)], 1), _c('v-card-actions')], 1), _c('v-row', {
    staticClass: "my-6"
  }, [_c('v-spacer'), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    attrs: {
      "color": "primary"
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v("저장")])], 1), _c('v-spacer')], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', {
    staticClass: "console-terms",
    attrs: {
      "fluid": "",
      "fill-height": ""
    }
  }, [_c('v-layout', {
    attrs: {
      "justify-center": ""
    }
  }, [_c('v-responsive', {
    attrs: {
      "max-width": "720",
      "width": "100%"
    }
  }, [_c('v-row', {
    staticClass: "mt-8",
    attrs: {
      "justify": "center",
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "pt-0 pb-0 headline text-start",
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("비밀번호 변경")]), _c('v-spacer')], 1), _c('v-card', {
    staticClass: "mt-4"
  }, [_c('v-card-text', {
    staticClass: "py-3"
  }, [_c('v-row', [_c('v-col', [_c('v-text-field', {
    attrs: {
      "type": "password",
      "label": "비밀번호",
      "persistent-placeholder": "",
      "outlined": "",
      "dense": "",
      "hide-details": ""
    },
    model: {
      value: _vm.user.password,
      callback: function ($$v) {
        _vm.$set(_vm.user, "password", $$v);
      },
      expression: "user.password"
    }
  })], 1), _c('v-col', [_c('v-text-field', {
    attrs: {
      "type": "password",
      "label": "비밀번호 확인",
      "persistent-placeholder": "",
      "outlined": "",
      "dense": "",
      "hide-details": ""
    },
    model: {
      value: _vm.user.passwordRepeat,
      callback: function ($$v) {
        _vm.$set(_vm.user, "passwordRepeat", $$v);
      },
      expression: "user.passwordRepeat"
    }
  })], 1)], 1)], 1), _c('v-divider'), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "text": "",
      "color": "primary"
    },
    on: {
      "click": _vm.saveUser
    }
  }, [_vm._v("저장")])], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function render(){
  var _vm$admin;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-layout', {
    attrs: {
      "justify-center": ""
    }
  }, [_c('v-responsive', {
    staticClass: "shop-product-view",
    attrs: {
      "max-width": "1200px",
      "width": "100%",
      "height": "100%"
    }
  }, [_c('v-row', {
    staticClass: "px-1",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "headline",
    attrs: {
      "cols": "auto"
    }
  }, [_vm.$route.params._admin ? _c('span', [_vm._v("관리자IP 상세")]) : _c('span', [_vm._v("관리자IP 생성")])]), _c('v-spacer')], 1), _c('v-card', {
    staticClass: "mx-1 mt-3"
  }, [_c('v-card-title', {
    staticClass: "subtitle-2"
  }, [_vm._v("기본정보")]), _c('v-card-text', [_c('v-autocomplete', {
    attrs: {
      "items": _vm.admins,
      "label": "ip를 등록할 관리자를 선택해 주세요."
    },
    scopedSlots: _vm._u([{
      key: "item",
      fn: function (_ref) {
        var item = _ref.item;
        return [_vm._v(" " + _vm._s(item.name) + " ")];
      }
    }, {
      key: "selection",
      fn: function (_ref2) {
        var item = _ref2.item;
        return [_vm._v(" " + _vm._s(item.name) + " ")];
      }
    }]),
    model: {
      value: _vm.admin,
      callback: function ($$v) {
        _vm.admin = $$v;
      },
      expression: "admin"
    }
  })], 1), _c('v-card-actions')], 1), (_vm$admin = _vm.admin) !== null && _vm$admin !== void 0 && _vm$admin._id ? _c('v-card', {
    staticClass: "mx-1 mt-3"
  }, [_c('v-card-title', {
    staticClass: "subtitle-2"
  }, [_vm._v("IP등록")]), _c('v-card-text', [_c('v-text-field', {
    attrs: {
      "label": "등록할 ip를 작성해 주세요"
    },
    model: {
      value: _vm.ip,
      callback: function ($$v) {
        _vm.ip = $$v;
      },
      expression: "ip"
    }
  })], 1), _c('v-card-actions')], 1) : _vm._e(), _c('v-row', {
    staticClass: "my-6"
  }, [_c('v-spacer'), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    attrs: {
      "color": "primary"
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v("저장")])], 1), _c('v-spacer')], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }